import React from "react";
// import background from "../../assets/Banner/slider.jpg";
import mobile_background from "../../assets/Banner/MobileSlider.jpg";
import tablet_background from "../../assets/Banner/tabletSlider.jpg";

import { useState ,useEffect} from "react";
export const Banner = () => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth)
    }
    
    window.addEventListener("resize", handleResize)
    
    handleResize()
    
    return () => { 
      window.removeEventListener("resize", handleResize)
    }
  }, [setWidth])
  return (
    <>
      <div className="bannerWapper">
        <div className="background-img">
          {/* {(width>=768)? <img src={tablet_background} alt="" />:<img src={(width<=450)?mobile_background} alt="" />} */}
           <img src={(width<=450)?mobile_background: tablet_background}alt="" /> 
          <div className="background_info">
            <div className="info_header">Your faithful partners</div>
            <div className="info_subheader">Get discount in all Products</div>
            <div className="info_description">For more than 20 years, we have worked to make cosmetics a source of beauty in Libya. Cosmetics are not determined by the shape, size, color or price, but by the original brand that gives you high quality. </div>

          </div>

        </div>
      </div>
    </>
  );
};
