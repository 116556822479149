import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Customer from '../../assets/whysection/customer.png';
import CustomerWhite from '../../assets/whysection/customer-white.png';
import Available from '../../assets/whysection/goods-available.png';
import AvailableWhite from '../../assets/whysection/goods-available-white.png';
import PesticideFree from '../../assets/whysection/pesticide-free.png';
import PesticidefreeWhite from '../../assets/whysection/pesticide-free-white.png';

export const WhyChoseUs = () => {
  return (
    <>
      <div className="whyChosesection">
        <Container>
          <Row>
            <Col className="Choose-us-section">
              <div className="section-title">
                <h1 className="page-title">Why Choose Us</h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="4" sm="12">
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <div className="flip-img">
                      <img src={Customer} alt="" />
                    </div>
                    <h3 className="animated">Curated Products</h3>
                  </div>
                  <div className="flip-card-back">
                    <div className="ltn__feature-icon-title">
                      <div className="ltn__feature-icon">
                        <div className="flip-img">
                          <img src={CustomerWhite} alt="#" />
                        </div>
                      </div>
                      <h3 className="animated">Curated Products</h3>
                    </div>
                    <div className="ltn__feature-info">
                      <p>
                      We have many beauty and personal care products for wholesale and retail.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="4" sm="12">
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <div className="flip-img">
                      <img src={Available} alt="" />
                    </div>
                    <h3 className="animated">All Kind Brand</h3>
                  </div>
                  <div className="flip-card-back">
                    <div className="ltn__feature-icon-title">
                      <div className="ltn__feature-icon">
                        <div className="flip-img">
                          <img src={AvailableWhite} alt="#" />
                        </div>
                      </div>
                      <h3 className="animated">All Kind Brand</h3>
                    </div>
                    <div className="ltn__feature-info">
                      <p>
                      We are trying to get the  global brands in the world of global cosmetics.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="4" sm="12">
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <div className="flip-img">
                      <img src={PesticideFree} alt="" />
                    </div>
                    <h3 className="animated">Pesticide Free Goods</h3>
                  </div>
                  <div className="flip-card-back">
                    <div className="ltn__feature-icon-title">
                      <div className="ltn__feature-icon">
                        <div className="flip-img">
                          <img src={PesticidefreeWhite} alt="#" />
                        </div>
                      </div>
                      <h3 className="animated">Save & Harmless  Goods</h3>
                    </div>
                    <div className="ltn__feature-info">
                      <p>
                      We always strive to get the safest and most harmless products.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
