import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Header, Footer } from "..";
import { Select } from "antd";
import Ariel from "../../assets/Brand/Ariel.png";
import Crest from "../../assets/Brand/Crest.png";
import Fairy from "../../assets/Brand/Fairy.png";
import Headandshoulders from "../../assets/Brand/headandshoulders.png";
import Herbalessences from "../../assets/Brand/herbal-essences.png";
import Oralb from "../../assets/Brand/oralb.png";
import Pamper from "../../assets/Brand/Pampers.png";
import Pantene from "../../assets/Brand/Pantene.png";
import Beautiful from "../../assets/Brand/beautiful.jpg";
import {
  Axe,
  Cif,
  Comfort,
  Dove,
  Lifebuoy,
  Lux,
  Sunsilk,
  Vaseline,
  Rexona,
} from "../../assets";

const { Option } = Select;

export const data = [
  {
    name: "Pampers",
    image: Pamper,
    brandName: "Baby Care",
    link: "https://www.pampers.com/en-us",
  },
  {
    name: "Head & Shoulders",
    image: Headandshoulders,
    brandName: "Beauty Care",
    link: "https://headandshoulders.com/en-us",
  },
  {
    name: "Herbal Essences",
    image: Herbalessences,
    brandName: "Beauty Care",
    link: "https://herbalessences.com/en-us/",
  },
  {
    name: "Axe",
    image: Axe,
    brandName: "Beauty & Personal Care",
    link: "https://herbalessences.com/en-us/",
  },
  {
    name: "Cif",
    image: Cif,
    brandName: "Home Care",
    link: "https://herbalessences.com/en-us/",
  },
  {
    name: "Comfort",
    image: Comfort,
    brandName: "Home Care",
    link: "https://herbalessences.com/en-us/",
  },
  {
    name: "Dove",
    image: Dove,
    brandName: "Beauty & Personal Care",
    link: "https://herbalessences.com/en-us/",
  },
  {
    name: "Lifebuoy",
    image: Lifebuoy,
    brandName: "Beauty & Personal Care",
    link: "https://herbalessences.com/en-us/",
  },
  {
    name: "Lux",
    image: Lux,
    brandName: "Beauty & Personal Care",
    link: "https://herbalessences.com/en-us/",
  },
  {
    name: "Sunsilk",
    image: Sunsilk,
    brandName: "Beauty & Personal Care",
    link: "https://herbalessences.com/en-us/",
  },
  {
    name: "Vaseline",
    image: Vaseline,
    brandName: "Beauty & Personal Care",
    link: "https://herbalessences.com/en-us/",
  },
  {
    name: "Rexona",
    image: Rexona,
    brandName: "Beauty & Personal Care",
    link: "https://herbalessences.com/en-us/",
  },
  {
    name: "Pantene",
    image: Pantene,
    brandName: "Beauty Care",
    link: "https://pantene.com/en-us",
  },
  {
    name: "Fairy",
    image: Fairy,
    brandName: "Home Care",
    link: "https://www.supersavvyme.co.uk/brands/fairy",
  },
  {
    name: "Crest",
    image: Crest,
    brandName: "Personal Health Care",
    link: "https://crest.com/en-us",
  },
  {
    name: "Oral-B",
    image: Oralb,
    brandName: "Personal Health Care",
    link: "https://oralb.com/",
  },
];

export const Product = () => {
  const { Option } = Select;

  const [selected, setSelected] = useState([]);
  const getFormattedData = (data) =>
    data.reduce((newData, instance, index) => {
      if (newData.hasOwnProperty(instance.brandName)) {
        newData[instance.brandName].push(instance);
      } else {
        newData[instance.brandName] = [{ ...instance }];
      }
      return newData;
    }, {});
  const [selectedData, setSelectedData] = useState(getFormattedData(data));

  function handleChangePreferences(value) {
    setSelected(value);
  }

  useEffect(() => {
    let newArray = getFormattedData(data);
    if (selected.length) {
      const filterData = data.filter((instance) =>
        selected.includes(instance.brandName)
      );
      newArray = getFormattedData(filterData);
    }
    setSelectedData(newArray);
  }, [selected]);

  return (
    <>
      <Header />
      <div className="header-image">
        <img src={Beautiful} alt="" />
      </div>
      <div className="brands-section">
        <Container>
          <div className="heading-section">
            <h1>BRANDS</h1>
            <h4>Iconic brands you can trust in your home</h4>
            <p>
            For more than 20 years, we have worked to make cosmetics a source of beauty in Libya. Cosmetics are not determined by the shape, size, color or price, but by the original brand that gives you high quality. We always invite all retailers in Libya to experience beauty on our own terms by involving them in products that offer superior care and quality.
            </p>
          </div>
          <Row>
            <div className="filter-section">
              Sort by:
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Select your preference"
                defaultValue={[]}
                onChange={handleChangePreferences}
                optionLabelProp="label"
              >
                <Option value="Beauty Care" label="Beauty Care">
                  <div className="demo-option-label-item">Beauty Care</div>
                </Option>
                <Option value="Baby Care" label="Baby Care">
                  <div className="demo-option-label-item">Baby Care</div>
                </Option>
                <Option
                  value="Beauty & Personal Care"
                  label="Beauty & Personal Care"
                >
                  <div className="demo-option-label-item">
                    Beauty & Personal Care
                  </div>
                </Option>
                <Option value="Home Care" label="Home Care">
                  <div className="demo-option-label-item">Home Care</div>
                </Option>
                <Option
                  value="Personal Health Care"
                  label="Personal Health Care"
                >
                  <div className="demo-option-label-item">
                    Personal Health Care
                  </div>
                </Option>
              </Select>
            </div>
            {Object.entries(selectedData).map((card, index) => {
              console.log(card, "<<>>");
              return (
                <>
                  <Row className="section-container">
                    <div className="Heading">
                      <h1>{card[0]}</h1>
                    </div>
                    {card[1].map((cardData) => {
                      return (
                        <Col xl="3" lg="3" md="6" sm="12">
                          <div class="brand-category__brands">
                            <div
                              class="brand"
                              data-entry-id="5Q9EelsH6gQ2Omaos2qUQk"
                            >
                              <div
                                class="image brand__image"
                                data-entry-id="LCrMZ7jA0U84QOWkUoe0w"
                              >
                                <img
                                  class="image__file"
                                  src={cardData.image}
                                  alt="Pampers logo"
                                  height="200"
                                  width="200"
                                />
                              </div>
                              <div className="text-dis">
                                <p class="brand__headline">{cardData.name}</p>
                                {/* <div class="formatted-text  brand__description">
                                  <p>{cardData.brandName}</p>
                                </div> */}
                                <Link to={cardData.link} className="brand-link">
                                  Visit site
                                </Link>
                              </div>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                  {/* <hr /> */}
                </>
              );
            })}
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};
