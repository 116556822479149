import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Baby from "../../assets/Product/baby.jpg";
import Beauty from "../../assets/Product/beauty.jpg";
import Home from "../../assets/Category/home-care.jpg";
import Personal from "../../assets/Category/beauty-personal-care.jpg";

const CategoryData = [
  {
    image: Beauty,
    discrption: "Beauty Care",
    link: "/product",
  },
  {
    image: Baby,
    discrption: "Baby care",
    link: "/product",
  },
  {
    image: Personal,
    discrption: "Personal health care",
    link: "/product",
  },
  {
    image: Home,
    discrption: "Home Care (Comfort & CIF)",
    link: "/product",
  },
];

export const Category = () => {
  return (
    <>
      <Container>
        <div className="Category-section">
          <div className="category-title">
            <h1 className="page-title">Category</h1>
          </div>
          <div>
            <Row>
              {CategoryData.map((card, index) => (
                <Col xl="3" lg="4" md="6" className="mobile-card">
                  <div className="category-card">
                    <Link to={card.link} className="cardLink">
                      <div className="category-body">
                        <img src={card.image} alt="" />
                        <div className="card-dis">{card.discrption}</div>
                      </div>
                    </Link>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
};
