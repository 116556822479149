import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
// import { Link } from "@mui/material";
import { Link } from "react-router-dom";
import { ListItemLink } from "../Linklist";
import Logo from "../../assets/logo.png";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    color: "#e0e3bd",
    "background-color": "#121212",
    // borderBottom: `1px solid ${theme.palette.divider}`,
    padding: "10px",
    marginBottom: "3rem",
  },
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 240,
  },
  hide: {
    display: "none",
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    fontFamily: '"Amarante", cursive',
    fontSize: "30px",
    flexGrow: 1,
  },
  link: {
    textTransform: "uppercase",
    color: "#e0e3bd",
    fontSize: "14px",
    margin: theme.spacing(1, 2),
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  brandLink: {
    textDecoration: "none",
    color: "#e0e3bd",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

export const Header = () => {
  const matches = useMediaQuery("(min-width:900px)");
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className="Navbar-header">
      <AppBar position="sticky" className="appBar">
        {/* <Container maxWidth="xl"> */}
        <Container className="header-container">
          <Toolbar className="toolbar">
            {matches ? (
              <>
                <Typography
                  variant="h6"
                  color="inherit"
                  noWrap
                  className="toolbarTitle"
                  style={{ display: "flex" }}
                >
                  <Link to="/">
                    <img src={Logo} alt="" />
                  </Link>
                </Typography>

                <Box mr={5}>
                  <Link color="textPrimary" to="/" className="link">
                    Home
                  </Link>
                  <Link color="textPrimary" to="/product" className="link">
                    Brands
                  </Link>
                  <Link color="textPrimary" to="/contect" className="link">
                    Contact
                  </Link>
                </Box>
              </>
            ) : (
              <>
                <div>
                  <div className="navbar-heading">
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={handleDrawerOpen}
                      edge="start"
                      // className={clsx(open && classes.hide)}
                    >
                      <MenuIcon />
                    </IconButton>
                    <Typography
                      variant="h6"
                      color="inherit"
                      noWrap
                      className="toolbarTitle"
                      style={{ display: "flex" }}
                    >
                      <img src={Logo} alt="" />
                      {/* <Link to="/" color="inherit" className="brandLink">
                      Jawharat Crimea Compay
                    </Link> */}
                    </Typography>
                  </div>

                  <Drawer
                    onEscapeKeyDown={handleDrawerClose}
                    onBackdropClick={handleDrawerClose}
                    variant="temporary"
                    anchor="left"
                    open={open}
                  >
                    <div>
                      <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "rtl" ? (
                          <ChevronRightIcon />
                        ) : (
                          <ChevronLeftIcon />
                        )}
                      </IconButton>
                    </div>
                    <Divider />
                    <List>
                      <ListItemLink primary="Home" to="/" />
                      <ListItemLink primary="Brands" to="/product" />
                      <ListItemLink primary="Contact" to="/contect" />
                    </List>
                  </Drawer>
                </div>
              </>
            )}
          </Toolbar>
        </Container>
        {/* </Container> */}
      </AppBar>
    </div>
  );
};
