import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import Quality from "../../assets/Core/highQuality.png";
import Support from "../../assets/Core/customerService.png";
import Delivery from "../../assets/Core/freeDelivery.png";
import Cashback from "../../assets/Core/Cashback.png";

export const CoreFeatures = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <div className="core-feature">
        <Container>
          <Row>
            <Col>
              <div className="core-feature-title">
                <h1 className="page-title ">Core Features</h1>
              </div>
            </Col>
          </Row>
        </Container>

        <div className="protect_bg">
          <div className="core-feature-wrapper">
            <Container>
              <Row className="crousal-style">
                <Carousel
                  ssr
                  partialVisible
                  removeArrowOnDeviceType={[]}
                  itemClass="image-item"
                  responsive={responsive}
                  arrows={true}
                  infinite={false}
                  showDots={true}
                  autoPlay={true}
                  autoPlaySpeed={25000}
                >
                  <div className="core-feature-card">
                    <div className="card-content">
                      <img src={Quality} alt="" />
                      <h2>Quality Product</h2>
                      <span>
                        {" "}
                        We care very much about the quality of the products we import.
                      </span>
                      <button className="commitment-button">
                        Our Commitment
                      </button>
                    </div>
                  </div>
                  <div className="core-feature-card">
                    <div className="card-content">
                      <img src={Support} alt="" />
                      <h2>Customer Support</h2>
                      <span>
                        {" "}
                        We care very much about our customers, their care and satisfaction is important to us.
                      </span>
                      <button className="commitment-button">
                        Our Commitment
                      </button>
                    </div>
                  </div>
                  <div className="core-feature-card">
                    <div className="card-content">
                      <img src={Delivery} alt="" />
                      <h2>Free Delivery</h2>
                      <span>
                        {" "}
                        Buy from and free delivery.
                      </span>
                      <button className="commitment-button">
                        Our Commitment
                      </button>
                    </div>
                  </div>
                  <div className="core-feature-card">
                    <div className="card-content">
                      <img src={Cashback} alt="" />
                      <h2>Sell  for credit</h2>
                      <span>
                        {" "}
                        Receive the goods and pay later.
                      </span>
                      <button className="commitment-button">
                        Our Commitment
                      </button>
                    </div>
                  </div>
                </Carousel>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};
