/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Logo from "../../assets/logo.png";

export const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="footer-top-area">
          <div className="container-fluid ">
            <Container>
              {/* <div className="footer-body"> */}
              <Row>
                <Col xxl="4" xl="4" lg="6" md="6" sm="6" xm="12">
                  <div className="footer-widget footer-about-widget">
                    <div>
                      <div class="footer-logo">
                        <div class="site-logo">
                          <img src={Logo} alt="" />
                        </div>
                      </div>
                      <p className="details">
                      We always invite all retailers in Libya to experience beauty on our own terms by involving them in products that offer superior care and quality.
                      </p>
                      {/* <div className="connect_socalIcon">
                          <ul>
                            <li>
                              <a className="facebook social-link">facebook</a>
                            </li>
                            <li>
                              {" "}
                              <a className="instagram social-link">instagram</a>
                            </li>
                            <li>
                              {" "}
                              <a className="twitter social-link">twitter</a>
                            </li>
                            <li>
                              {" "}
                              <a className="linkedin social-link">linkedin</a>
                            </li>
                          </ul>
                        </div> */}
                    </div>
                  </div>
                </Col>
                <Col xxl="4" xl="4" lg="6" md="6" sm="6" xm="12">
                  <div className="footer-widget">
                    <h4 class="footer-title">Quick Links</h4>
                    <div class="footer-menu">
                      <ul>
                        <li>
                          <a href="">Home</a>
                        </li>
                        <li>
                          <a href="">Brand</a>
                        </li>
                        <li>
                          <a href="">Contact page</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>

                <Col xxl="4" xl="4" lg="6" md="6" sm="6" xm="12">
                  <div className="footer-widget">
                    <h4 class="footer-title">Contact Details</h4>
                    <div class="footer-menu">
                      <ul>
                        <li>
                          <a href="">Telephone:+218 91-6233634</a>
                        </li>
                        <li>
                          <a href="">Email:kouonuzl@ kouonuzlibya.com</a>
                        </li>
                        <li>
                          <a href="">Address:Libya / Tarabulus / Tripoli / Alkremiya </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* </div> */}
        </div>
      </div>
      <div className="ltn__copyright-area ltn__copyright-2 section-bg-7  plr--5">
        <div className="ltn__border-top-2">
          <Container>
            <Row>
              <Col xl="12" lg="12">
                <div className="ltn__copyright-design clearfix">
                  <p>
                    All Rights Reserved &#169; Company{" "}
                    <span className="current-year">2022</span>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};
