import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Hand from "../../assets/Investor.png";
import Supply from "../../assets/Supply.png"
import Customer from "../../assets/Customer.png"
export const AboutProduct = () => {
  return (
    <Container>
      <div className="about-heading">
        <Row>
          <Col className="Choose-us-section">
            <div className="section-title">
              <h1 className="page-title">our footprint</h1>
            </div>
          </Col>
        </Row>
      </div>
      <div className="about">
        <Row className="about-section">
          <Col md="12">
            <div className="titlepage text_align_left">
              <h2>Reducing our footprint one step at a time </h2>
              {/* <p>
                  English. Many desktop publishing packages and web page editors
                  now use Lorem Ipsum as their default model text, and a search
                  for
                </p> */}
              {/* <Link to="/" className="read_more">
                  About More
                </Link> */}
            </div>
          </Col>
          {/* <Col md="5">
              <div className="about_img">
                <img src={VirusProtection} alt="#" />
              </div>
            </Col> */}
        </Row>
        <div className="card-about-section">
          <Row>
            <Col md="4">
              <div className="about-card">
                <div className="image-section">
                  <img
                    class="image__files"
                    src={Hand}
                    alt="Advocates-icon"
                    height="82"
                    width="101"
                  />
                  <p class="home-page-small-link__text">Investor Relations</p>
                </div>
                <div className="about-link-content">
                  <button className="commitments-button">
                  Investor relations 
                  </button>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="about-card">
                <div className="image-section">
                  <img
                    class="image__files"
                    src={Customer}
                    alt="Advocates-icon"
                    height="82"
                    width="101"
                  />
                  <p class="home-page-small-link__text">Customer Satisfaction</p>
                </div>
                <div className="about-link-content">
                  <button className="commitments-button">
                  customer satisfaction 
                  </button>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="about-card">
                <div className="image-section">
                  <img
                    class="image__files"
                    src={Supply}
                    alt="Advocates-icon"
                    height="82"
                    width="101"
                  />
                  <p class="home-page-small-link__text">Supply Chain</p>
                </div>
                <div className="about-link-content">
                  <button className="commitments-button">
                  supply chain description
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
};
